import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { FirebaseAppProvider } from "reactfire";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import FeedbackProvide from "./providers/feedback/feedback";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const firebaseConfig = {
  apiKey: "AIzaSyAp-xUkBVK2O552etrXHp8Anu_V8AFHpoc",
  authDomain: "youth-commission-udupi.firebaseapp.com",
  projectId: "youth-commission-udupi",
  storageBucket: "youth-commission-udupi.appspot.com",
  messagingSenderId: "504645962544",
  appId: "1:504645962544:web:167ff5aca672e68c8196f5",
  measurementId: "G-37TLLBWH0C",
};
const firebaseConfigForDev = {
  apiKey: "AIzaSyAHxNOj5w7nFZxkvd5I7i8HJ8iQDBuhnVM",
  authDomain: "youthcommissiondevenv.firebaseapp.com",
  projectId: "youthcommissiondevenv",
  storageBucket: "youthcommissiondevenv.appspot.com",
  messagingSenderId: "22924645352",
  appId: "1:22924645352:web:4871eea245208e7af68a5d",
  measurementId: "G-RWXCGP507B",
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const firestore = getFirestore(app);

root.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <App />
    </FirebaseAppProvider>
  </React.StrictMode>
);
reportWebVitals();
