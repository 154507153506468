import { Avatar, Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { SECONDARY_THEME_COLOR } from "../../../../providers/theme/colors/colors";
import { ArrowRightAlt } from "@mui/icons-material";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { collection, orderBy, query } from "firebase/firestore";

interface Props {}

function TopNews() {
  const navigate = useNavigate();
  const firestore = useFirestore();
  const collectionDataForNewsList = collection(firestore, "news");
  const chapterCollectionQuerNewsList = query(
    collectionDataForNewsList,
    orderBy("createdAt", "desc")
  );

  const { data: newsList } = useFirestoreCollectionData(
    chapterCollectionQuerNewsList
  );

  return (
    <Box>
      <Box mb={2} display={"flex"} justifyContent={"space-between"}>
        <Typography variant="h4" fontWeight={"600"}>
          Latest news
        </Typography>
        <Box
          display={"flex"}
          mt={1}
          borderBottom={`3px solid ${SECONDARY_THEME_COLOR}`}
          width={"fit-content"}
          onClick={() => {
            navigate("/news");
            window.scrollTo(0, 0);
          }}
          sx={{ cursor: "pointer" }}
        >
          <Typography variant="body2" fontWeight={"600"}>
            View all
          </Typography>
          <ArrowRightAlt />
        </Box>
      </Box>

      <Box
        display={"flex"}
        position={"relative"}
        mt={2}
        borderRadius={2}
        sx={{ backgroundColor: "#09084F" }}
        // minHeight={250}
      >
        <Grid container spacing={3} py={4}>
          {newsList?.map((item, index) => {
            return (
              index < 6 && (
                <Grid item md={4} lg={4} sm={12} mb={1}>
                  <Box display={"flex"} mx={2} minHeight={50}>
                    <Avatar sx={{ backgroundColor: "#333260" }}>
                      <Typography color="#F48407">{index + 1}</Typography>
                    </Avatar>
                    <Link
                      to={`/news/${item?.NO_ID_FIELD}`}
                      style={{ textDecoration: "none" }}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      <Box ml={2}>
                        <Typography
                          color="#ffffff"
                          sx={{
                            fontWeight: 600,
                            display: "-webkit-box",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            whiteSpace: "normal",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                        >
                          {item?.title}
                        </Typography>
                      </Box>
                    </Link>
                  </Box>
                  {newsList.length > 2 && (
                    <Box
                      display={"flex"}
                      height={2}
                      sx={{
                        backgroundColor: "#3D3C6C",
                      }}
                      mt={4}
                      mx={3}
                    />
                  )}
                </Grid>
              )
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}

export default TopNews;
