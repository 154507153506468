import { Avatar, Box, Grid, Typography } from "@mui/material";
import React from "react";
import MainLayout from "../../layouts/main_layout/main_layout";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { collection, orderBy, query } from "firebase/firestore";
import TrendingNews from "../home/components/trending_news/trending_news";
import TopNews from "../home/components/top_news/top_news";
import { Link } from "react-router-dom";

interface Props {}

function NewsList(props: Props) {
  const {} = props;

  const firestore = useFirestore();
  const collectionDataForYuvadabazo = collection(firestore, "news");
  const chapterCollectionQueryForYuvaDabazo = query(
    collectionDataForYuvadabazo,
    orderBy("createdAt", "desc")
  );

  const { data: newsList } = useFirestoreCollectionData(
    chapterCollectionQueryForYuvaDabazo
  );

  return (
    <MainLayout>
      <Box my={4}>
        <Box mb={2} display={"flex"} justifyContent={"space-between"}>
          <Typography variant="h4" fontWeight={"600"}>
            News
          </Typography>
        </Box>

        <Box
          display={"flex"}
          position={"relative"}
          mt={2}
          borderRadius={2}
          sx={{ backgroundColor: "#09084F" }}
          // minHeight={250}
        >
          <Grid container spacing={3} py={4}>
            {newsList?.map((item, index) => {
              return (
                <Grid item md={4} lg={4} sm={12} mb={1}>
                  <Box display={"flex"} mx={2} minHeight={50}>
                    <Avatar sx={{ backgroundColor: "#333260" }}>
                      <Typography color="#F48407">{index + 1}</Typography>
                    </Avatar>
                    <Link
                      to={`/news/${item?.NO_ID_FIELD}`}
                      style={{ textDecoration: "none" }}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      <Box ml={2}>
                        <Typography
                          color="#ffffff"
                          fontWeight={600}
                          sx={{
                            display: "-webkit-box",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            whiteSpace: "normal",

                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                        >
                          {item?.title}
                        </Typography>
                      </Box>
                    </Link>
                  </Box>
                  {newsList.length > 2 && (
                    <Box
                      display={"flex"}
                      height={2}
                      sx={{
                        backgroundColor: "#3D3C6C",
                      }}
                      mt={4}
                      mx={3}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </MainLayout>
  );
}

export default NewsList;
