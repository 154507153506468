import React, { ChangeEventHandler, useEffect, useState } from "react";
import MainLayout from "../../layouts/main_layout/main_layout";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { Close, Remove } from "@mui/icons-material";
import { addDoc, collection, query } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { useFeedback } from "../../providers/feedback/feedback";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import {
  Deanery,
  deaneryList,
  heirarcyList,
  Parish,
  parishListMap,
  trainingList,
} from "../yuva_dabazo/yuva_dabazo";

function ProAndYoucatTraining() {
  const { showSnackbar } = useFeedback();
  const navigate = useNavigate();
  const firestore = useFirestore();
  const [parishList, setParishList] = useState<Parish[]>([]);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [_selectedDeanery, _setSelectedDeanery] = useState<{
    id: Number;
    name: string;
  } | null>(null);
  const [_selectedParish, _setSelectedParish] = useState<{
    id: Number;
    value: string;
  } | null>(null);
  const [RepresentativeFrom, setRepresentativeFrom] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [participatingFrom, setParticipatingFrom] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [reciept, setReciept] = useState<File | null>(null);
  const [participateName, setParticipateName] = useState("");

  function submit() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
    } else {
      const usersCollection = collection(firestore, "proYoucatTraining");

      addDoc(usersCollection, {
        createdAt: Date().toString(),
        updatedAt: Date().toString(),
        deaneryName: _selectedDeanery,
        parishName: _selectedParish,
        email: email,
        fullName: participateName,
        trainingProgram: participatingFrom,
        RepresentativeFrom: RepresentativeFrom,
      }).then((res) => {
        showSnackbar("Registration successful!");
        navigate("/");
      });
    }
  }

  const handleDeaneryChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: Deanery | null
  ) => {
    if (value) {
      _setSelectedDeanery({ id: value.id, name: value.name });
      setParishList(parishListMap[value.name] || []);
      _setSelectedParish(null);
    } else {
      _setSelectedDeanery(null);
      setParishList([]);
    }
  };

  return (
    <MainLayout>
      <Box my={4}>
        <Box>
          <Typography
            fontWeight={500}
            textAlign={"center"}
            variant={isMobile ? "h6" : "h4"}
          >
            Youcat and PRO Training - 2025
          </Typography>
          <Typography
            fontWeight={500}
            textAlign={"center"}
            variant={isMobile ? "subtitle1" : "h6"}
          >
            Registration Form
          </Typography>
        </Box>
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography variant="caption" fontWeight={600}>
                  Note: For any modifications or clarifications regarding your
                  registration, please reach out to :
                </Typography>
                <Typography variant="caption" fontWeight={600}>
                  Fr.Steevan Fernandes -9945733168, Nithin Baretto - 7406411821
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <Box>
                <Typography mb={1} fontWeight={500}>
                  Full Name <span style={{ color: "red" }}>*</span>:
                </Typography>
                <TextField
                  fullWidth
                  value={participateName}
                  onChange={(e) => setParticipateName(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <Box>
                <Typography mb={1} fontWeight={500}>
                  Training Program <span style={{ color: "red" }}>*</span>:
                </Typography>
                <Autocomplete
                  fullWidth
                  value={
                    trainingList.find(
                      (option) => participatingFrom?.id === option.id
                    ) || null
                  }
                  onChange={(e, val) => {
                    setParticipatingFrom({ id: +val?.id!, name: val?.name! });
                  }}
                  options={trainingList}
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <Box>
                <Typography mb={1} fontWeight={500}>
                  Representative From<span style={{ color: "red" }}>*</span>:
                </Typography>
                <Autocomplete
                  fullWidth
                  value={
                    heirarcyList.find(
                      (option) => RepresentativeFrom?.id === option.id
                    ) || null
                  }
                  onChange={(e, val) => {
                    setRepresentativeFrom({ id: +val?.id!, name: val?.name! });
                  }}
                  options={heirarcyList}
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <Box>
                <Typography mb={1} fontWeight={500}>
                  Deanery name <span style={{ color: "red" }}>*</span>:
                </Typography>
                <Autocomplete
                  fullWidth
                  value={
                    deaneryList.find(
                      (option) => _selectedDeanery?.id === option.id
                    ) || null
                  }
                  onChange={handleDeaneryChange}
                  options={deaneryList}
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </Box>
            </Grid>
            {/* <Grid item xl={4} lg={4} md={4} sm={12} xs={12}></Grid> */}
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <Box>
                <Typography mb={1} fontWeight={500}>
                  Parish name <span style={{ color: "red" }}>*</span>:
                </Typography>
                <Autocomplete
                  fullWidth
                  value={
                    parishList.find(
                      (option: Parish) => _selectedParish?.id === option.id
                    ) || null
                  }
                  onChange={(e: React.SyntheticEvent, value: Parish | null) =>
                    _setSelectedParish(value)
                  }
                  options={parishList}
                  getOptionLabel={(option: Parish) => option.value || ""}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                  disabled={!parishList.length}
                />
              </Box>
            </Grid>

            {/*              
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}></Grid> */}
            <Grid item xl={4} md={4} sm={12} xs={12}>
              <Box>
                <Typography mb={1} fontWeight={500}>
                  Email <span style={{ color: "red" }}>*</span>:
                </Typography>
                <TextField
                  fullWidth
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError("");
                  }}
                  helperText={error}
                  FormHelperTextProps={{
                    sx: { color: "red" }, // Set helperText color to red
                  }}
                />
              </Box>
            </Grid>
            <Grid item xl={4} md={4} sm={12} xs={12}>
              <Typography mb={1} fontWeight={500}>
                Upload payment reciept
                <span style={{ color: "red" }}>*</span>:
              </Typography>
              {!reciept ? (
                <TextField
                  fullWidth
                  type="file"
                  value={reciept}
                  onChange={(e) => {
                    const fileInput = e.target as HTMLInputElement;

                    if (fileInput.files && fileInput.files[0]) {
                      const allowedTypes = ["jpeg", "jpg", "png"]; // Allowed MIME types
                      if (
                        allowedTypes.includes(
                          fileInput?.files?.[0]?.name
                            .split(".")
                            ?.pop()
                            ?.toLowerCase()!
                        )
                      ) {
                        setReciept(fileInput.files[0]);
                      } else {
                        e.target.value = "";
                        showSnackbar("Please select a valid image file");
                      }
                    }
                  }}
                  helperText={error}
                  FormHelperTextProps={{
                    sx: { color: "red" },
                  }}
                  inputProps={{
                    accept: "image/*",
                  }}
                />
              ) : (
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                >
                  <Typography variant="body1" fontWeight={600}>
                    {reciept?.name}
                  </Typography>
                  <Box display={"flex"} ml={1}>
                    <IconButton
                      size={"small"}
                      onClick={() => {
                        setReciept(null);
                      }}
                      style={{ color: "#000000" }}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
        <Grid container>
          <Grid lg={10} xl={10} md={10} sm={12} xs={12}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              mt={5}
            >
              <Button
                sx={{
                  width: "fit-content",
                  minWidth: 300,
                  justifyContent: "center",
                }}
                variant="contained"
                onClick={submit}
                disabled={
                  !_selectedParish?.id ||
                  !participatingFrom ||
                  !RepresentativeFrom ||
                  !email ||
                  !_selectedDeanery ||
                  !reciept ||
                  !participateName
                }
              >
                <Typography fontWeight={500}>Register</Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </MainLayout>
  );
}
export default ProAndYoucatTraining;
